import { request } from '@/utils/request'

// 获取用户信息
export function getUserInfo () {
  return request({
    url: '/user/info',
    method: 'get'
  })
}
// 获取用户列表
export function getUserList () {
  return request({
    url: '/user/list',
    method: 'get'
  })
}
// 修改用户状态
export function setUserState (data) {
  return request({
    url: '/user/updateState',
    method: 'post',
    data
  })
}

// 续费 设置用户到期时间
export function setUserRenew (data) {
  return request({
    url: '/user/renew',
    method: 'post',
    data
  })
}

// 用户设置备注
export function setUserRemark (data) {
  return request({
    url: '/user/setRemark',
    method: 'post',
    data
  })
}
