<template>
  <div>
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <h5 class="font-semibold m-0">用户管理</h5>
              </a-col>
              <a-col class="function" :span="24" :md="12"
                style="display: flex; align-items: center; justify-content: flex-end">
                <!-- <a-button @click="add" type="primary" size="small">
                  开通会员
                </a-button>
                <a-button @click="editClassify" size="small">配置板块</a-button>
                <a-button @click="syncFollow" size="small">同步</a-button>
                <a-button @click="exportExcel" size="small">导出</a-button>
                <a-button @click="batchDel" size="small" type="danger">批量删除</a-button> -->
                <!-- <a-button size="small" @click="test">测试爬虫</a-button> -->
                <!-- <a-button size="small" @click="chattest">测试chatgpt</a-button> -->
              </a-col>
            </a-row>
          </template>
          <a-table :columns="table1Columns" :data-source="table1Data" :pagination="false" :rowKey="record => record.id"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
            <template slot="name" slot-scope="name">
              <a slot="name" :href="'https://twitter.com/' + name" target="_blank">{{ name }}</a>
            </template>

            <template slot="state" slot-scope="state">
              <a-tag color="green" v-if="state == 1">
                正常
              </a-tag>
              <a-tag color="red" v-else>
                停用
              </a-tag>
            </template>
            <template slot="action" slot-scope="row">
              <a-button type="link" size="small" :data-id="row.key" @click="setUserState(row)">
                <span v-if="row.state == 1">停用</span>
                <span v-else>启用</span>
              </a-button>
              <a-button type="link" size="small" :data-id="row.key" @click="showRenew(row)">续费</a-button>
              <a-button type="link" size="small" :data-id="row.key" @click="showRemark(row)">备注</a-button>
            </template>

          </a-table>
        </a-card>
      </a-col>
    </a-row>

    <!-- 续费 -->
    <a-modal :visible="visible.renew.show" title="续费" @ok="setRenew" @cancel="visible.renew.show = false">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" ref="form">
        <!-- <a-form-item label="续费周期">
          <a-input-group compact>
            <a-input  v-decorator="['num', { rules: [{ required: true, message: '请输入续费周期' }] }]" style="width: 80%;" />
           
          </a-input-group>
        </a-form-item> -->

        <a-form-item label="续费周期">
          <a-input v-decorator="['num', { rules: [{ required: true, message: '请输入续费周期' }] }]" />
        </a-form-item>
        <a-form-item label="续费周期">
          <a-select v-decorator="['cycle', { rules: [{ required: true, message: '请选择周期' }] }]">
            <a-select-option value="day">
              天
            </a-select-option>
            <a-select-option value="month">
              月
            </a-select-option>
            <a-select-option value="year">
              年
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="付款金额">
          <a-input v-decorator="['amount', { rules: [{ required: true, message: '请输入付款金额' }] }]" />
        </a-form-item>
        <a-form-item label="交易hash">
          <a-input v-decorator="['hash', { rules: [{ required: true, message: '请输入交易哈希' }] }]" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal :visible="visible.remark.show" title="备注" @ok="setRemark" @cancel="visible.remark.show = false">
      <a-form :form="remarkForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" ref="remarkForm">
        <a-form-item label="备注">
          <a-textarea v-decorator="['remark', { rules: [{ required: true, message: '请输入备注内容' }] }]"
            :auto-size="{ minRows: 5, maxRows: 10 }" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

const formattedDate = (dateString, format) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以加1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  if (format == 'yyyy-mm-dd h:m:s') {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else if (format == 'yyyy-mm-dd') {
    return `${year}-${month}-${day}`;
  } else if (format == 'yyyy-mm-dd h') {
    return `${year}-${month}-${day} ${hours}`;
  }
}
const table1Columns = [
  {
    title: '用户id',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' },
    width: 100,

  },
  {
    title: '钱包',
    dataIndex: 'walletAddress',
    width: 380,

  },
  // {
  //   title: '测试次数',
  //   dataIndex: 'promptTestNum',
  // },
  {
    title: '状态',
    key: 'state',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
    width: 80,
    filters: [{
      text: '正常',
      value: 1,
    },
    {
      text: '停用',
      value: 0,
    }],
    onFilter: (value, record) => {
      return record.state == value
    },
  },
  {
    title: '到期时间',
    dataIndex: 'expirationDate',
    scopedSlots: { customRender: 'expirationDate' },
    width: 180,

  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 100,
    scopedSlots: { customRender: 'remark' },
  },
  {
    scopedSlots: { customRender: 'action' },
    key: 'action',
    width: 190,
  }
];
import { getUserList, setUserState, setUserRenew, setUserRemark } from "@/api/user.js";
export default ({
  components: {
  },
  data() {
    return {
      table1Data: [],
      table1Columns: table1Columns,
      visible: {
        add: {
          show: false
        },
        edit: {
          show: false
        },
        renew: {
          id: null,
          show: false
        },
        remark: {
          id: null,
          show: false
        }
      },
      form: this.$form.createForm(this),
      remarkForm: this.$form.createForm(this),
      editForm: this.$form.createForm(this),
      selectedRowKeys: [],
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    setRemark() {
      let key = 1;
      this.$message.loading({ content: '保存中...', key });
      this.form.validateFields((err, values) => {
        if (!err) {
          let remark = this.remarkForm.getFieldValue('remark')
          let id = this.visible.remark.id;
          setUserRemark({
            remark, id
          }).then(res => {
            this.$message.success({ content: '保存成功', key, duration: 2 });
            this.visible.remark.show = false;
            this.getList();
          }).catch(err => {
            this.$message.error({ content: '保存失败！' + err, key, duration: 2 });
          })
        }
      });
    },
    showRemark(row) {
      this.visible.remark.id = row.id
      this.visible.remark.show = true;
      this.$nextTick(() => {
        if (this.remarkForm) {
          this.remarkForm.resetFields();
          this.remarkForm.setFieldsValue({ remark: row.remark });
        }
      });
    },
    // changeCycle(value){
    //   console.log(value);
    // },
    setRenew() {
      let key = 1;
      this.$message.loading({ content: '续费中...', key });
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id = this.visible.renew.id;
          setUserRenew(values).then(res => {
            this.$message.success({ content: '续费成功', key, duration: 2 });
            this.visible.renew.show = false;
            this.getList();
          }).catch(err => {
            this.$message.error({ content: '续费失败！' + err, key, duration: 2 });
          })
        }
      });
    },
    // 续费
    showRenew(row) {
      this.visible.renew.id = row.id
      this.visible.renew.show = true;
      this.$nextTick(() => {
        if (this.form) {
          this.form.resetFields();
        }
      });
    },
    // 停用 启用
    setUserState(row) {
      let state = row.state == 0 ? 1 : 0;
      let msg = state == 0 ? '停用' : '启用';
      setUserState({ id: row.id, state }).then(res => {
        this.$message.success({ content: msg + '成功', duration: 2 });
        this.getList();
      })
    },
    getList() {
      getUserList().then(res => {
        let data = res.data.data;
        data.forEach(item => {
          item.address = this.truncateMiddle(item.walletAddress, 7, 5);
          if (item.expirationDate) {
            item.expirationDate = formattedDate(item.expirationDate, 'yyyy-mm-dd h:m:s');
          }
        })
        this.table1Data = data;
      })
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    truncateMiddle(str, frontLen, endLen) {
      const totalLen = frontLen + endLen;
      if (str.length <= totalLen) {
        return str;
      }
      const start = str.slice(0, frontLen);
      const end = str.slice(-endLen);
      return `${start}...${end}`;
    },
  }
})

</script>

<style lang="scss" scoped></style>